import "./footer.scss"

const Footer = () => {
    return (
        <div className="footer">
            <div className="container">
                <span>Thank you for your visit</span>
            </div>
        </div>
    );
}

export default Footer;
